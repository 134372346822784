import { SigningTemplateSlot } from '@app/models/documents/signing-template-slot.model';
import { Employee } from '@app/models/employee/employee.model';
import { SlotAssignmentOrder } from '@documents-v2/types/slot-assignment-order.type';

export interface SlotAssignmentPayload {
    slotId: number;
    employeeId: number;
}

/**
 * This represents the assignment to a SigningTemplateSlot
 */
export class SlotAssignment {
    hint = '';

    private _employee: Employee;
    private _permanentSlotAssignment = null;

    constructor(public slot: SigningTemplateSlot) {
        if (this.slot.hasPermanentAssignee()) {
            this._employee = slot.assignee;
            this.permanentSlotAssignment = true;
        }
    }

    get permanentSlotAssignment(): boolean {
        return this._permanentSlotAssignment;
    }

    set permanentSlotAssignment(val: boolean) {
        this._permanentSlotAssignment = val;
        this.slot.assignee = val ? this._employee : null;
    }

    get order(): SlotAssignmentOrder {
        return this.slot.order || 0;
    }

    set order(val: SlotAssignmentOrder) {
        this.slot.order = val;
    }

    set employee(val: Employee | null) {
        this._employee = val;

        if (this.permanentSlotAssignment) {
            this.slot.assignee = val;
        }
    }

    get employee(): Employee {
        return this._employee;
    }

    toPayload(): SlotAssignmentPayload {
        return {
            slotId: this.slot.id,
            employeeId: this.employee.id || this.slot.assigneeId,
        };
    }

    toSigningTemplateSlot(): SigningTemplateSlot {
        this.slot.order = this.order;
        this.slot.assignee = this.employee ?? null;
        return this.slot;
    }
}
